/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import './src/styles/globals.css'
import './src/styles/custom.css'

import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
export const wrapRootElement = ({ element }) => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey="6LdUlIslAAAAAPuEtRlQT9to25c00RxCeK5g4SpE">
            {element}
        </GoogleReCaptchaProvider>
    )
}